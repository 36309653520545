'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styles = require('@material-ui/core/styles');

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _styles2 = require('./styles');

var _Button = require('@material-ui/core/Button');

var _Button2 = _interopRequireDefault(_Button);

var _appCore = require('@manakin/app-core');

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var ExternalLinkCard = function ExternalLinkCard(props) {
	var classes = props.classes,
	    item = props.item,
	    _props$disabled = props.disabled,
	    disabled = _props$disabled === undefined ? false : _props$disabled,
	    theme = props.theme,
	    externalLink = props.externalLink;

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var textStyle = _extends({}, item && item.backgroundColor && {
		color: theme.palette.getContrastText(item.backgroundColor)
	});

	var handleOpenLinkClick = function handleOpenLinkClick() {
		window.open(externalLink.buttonUrl, externalLink.openInSameWindow ? '_self' : '_blank');
	};

	var handleClick = function handleClick() {
		if (disabled && props.onDisabledClick) {
			props.onDisabledClick();
		}
	};

	return _react2.default.createElement(
		'div',
		{
			className: (0, _classnames2.default)(classes.root, classes.bookCheck, classes.fullWidth, _defineProperty({}, classes.disabled, disabled)),
			style: _extends({}, item && item.backgroundColor && {
				backgroundColor: item.backgroundColor
			}),
			onClick: handleClick
		},
		_react2.default.createElement(
			'div',
			{ className: classes.backgroundImage },
			externalLink && externalLink.image && _react2.default.createElement(_appCore.LoadImage, { src: externalLink.image.url || '', placeholder: externalLink.image.thumbnailUrl || '' })
		),
		_react2.default.createElement(
			'div',
			{ className: classes.externalLinkFlex },
			_react2.default.createElement(
				'div',
				{ className: classes.externalLinkContent },
				_react2.default.createElement(
					_Typography2.default,
					{ component: 'h5', variant: 'h5', style: textStyle, className: (0, _classnames2.default)(classes.bodytitle, classes.name) },
					item && item.title || t('app.workforms.external-link.title')
				),
				_react2.default.createElement(
					_Typography2.default,
					{ component: 'p', variant: 'body1', style: textStyle, className: classes.body },
					externalLink && externalLink.introText || t('app.workforms.external-link.description')
				)
			),
			_react2.default.createElement(
				_Button2.default,
				{
					variant: 'contained',
					color: 'primary',
					fullWidth: true,
					style: textStyle,
					className: (0, _classnames2.default)(classes.button, classes.externalLinkButton),
					onClick: handleOpenLinkClick,
					disabled: disabled
				},
				externalLink && externalLink.buttonText || t('app.workforms.external-link.button-text')
			)
		)
	);
};

exports.default = (0, _styles.withStyles)(_styles2.styles, { name: 'AppExternalLinkCard', withTheme: true })(ExternalLinkCard);