'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _ElementGridCard = require('@manakin/app-core/ElementCard/ElementGridCard');

var _ElementGridCard2 = _interopRequireDefault(_ElementGridCard);

var _EntryTestCard = require('@manakin/app-core/ElementCard/EntryTestCard');

var _EntryTestCard2 = _interopRequireDefault(_EntryTestCard);

var _TestCard = require('@manakin/app-core/ElementCard/TestCard');

var _TestCard2 = _interopRequireDefault(_TestCard);

var _FactCardsCard = require('./FactCardsCard');

var _FactCardsCard2 = _interopRequireDefault(_FactCardsCard);

var _ExternalLinkCard = require('./ExternalLinkCard');

var _ExternalLinkCard2 = _interopRequireDefault(_ExternalLinkCard);

var _reactBottomScrollListener = require('react-bottom-scroll-listener');

var _reactBottomScrollListener2 = _interopRequireDefault(_reactBottomScrollListener);

var _KeyboardArrowUp = require('@material-ui/icons/KeyboardArrowUp');

var _KeyboardArrowUp2 = _interopRequireDefault(_KeyboardArrowUp);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _scrollTo = require('scroll-to');

var _scrollTo2 = _interopRequireDefault(_scrollTo);

var _Grid = require('@material-ui/core/Grid');

var _Grid2 = _interopRequireDefault(_Grid);

var _graphql = require('./graphql');

var _reactApollo = require('react-apollo');

var _appCore = require('@manakin/app-core');

var _Dialog = require('@material-ui/core/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _lib = require('../lib');

var _styles = require('@material-ui/core/styles');

var _reactI18next = require('react-i18next');

var _lib2 = require('@manakin/app-core/lib');

var _DigitalBookCard = require('./DigitalBookCard');

var _DigitalBookCard2 = _interopRequireDefault(_DigitalBookCard);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _styles.makeStyles)(function (theme) {
	return (0, _styles.createStyles)({
		root: _extends({
			backgroundColor: '#001f3f'
		}, theme.manakin.largeWrapper, _defineProperty({
			paddingLeft: 0,
			paddingRight: 0,
			paddingBottom: '4rem'
		}, theme.breakpoints.up('md'), {
			paddingBottom: '16rem'
		})),
		grid: _extends({}, theme.manakin.largeWrapper, {
			marginBottom: '64px',
			paddingLeft: 0,
			paddingRight: 0
		}),
		up: _defineProperty({
			position: 'fixed',
			left: '50%',
			bottom: 0,
			width: '50px',
			height: '50px',
			backgroundColor: theme.palette.primary[500],
			transform: 'translateX(-50%) translateY(100%)',
			zIndex: '99',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			transition: 'transform .3s',
			color: theme.palette.getContrastText(theme.palette.primary[500])
		}, theme.breakpoints.up('md'), {
			display: 'none'
		}),
		show: {
			transform: 'translateX(-50%) translateY(0)'
		},
		dialogPaper: {
			padding: '32px'
		},
		body: {
			margin: 0
		}
	});
}, {
	name: 'AppElementsRoot'
});

var isMounted = false;
var Elements = function Elements(props) {
	//props
	var items = props.items,
	    elementResults = props.elementResults,
	    _props$boxResults = props.boxResults,
	    boxResults = _props$boxResults === undefined ? {} : _props$boxResults;

	var classes = useStyles();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	//state hooks


	var _useState = (0, _react.useState)(false),
	    _useState2 = _slicedToArray(_useState, 2),
	    show = _useState2[0],
	    setShow = _useState2[1];

	var _useState3 = (0, _react.useState)(''),
	    _useState4 = _slicedToArray(_useState3, 2),
	    image = _useState4[0],
	    setImage = _useState4[1];

	var _useState5 = (0, _react.useState)(false),
	    _useState6 = _slicedToArray(_useState5, 2),
	    showCards = _useState6[0],
	    setShowCards = _useState6[1];

	var _useState7 = (0, _react.useState)(false),
	    _useState8 = _slicedToArray(_useState7, 2),
	    showDialog = _useState8[0],
	    setShowDialog = _useState8[1];

	var _useState9 = (0, _react.useState)(false),
	    _useState10 = _slicedToArray(_useState9, 2),
	    showTestDialog = _useState10[0],
	    setShowTestDialog = _useState10[1];

	var _useState11 = (0, _react.useState)(0),
	    _useState12 = _slicedToArray(_useState11, 2),
	    testPercentage = _useState12[0],
	    setTestPercentage = _useState12[1];

	var _useState13 = (0, _react.useState)({}),
	    _useState14 = _slicedToArray(_useState13, 2),
	    externalLink = _useState14[0],
	    setExternalLink = _useState14[1];

	var boxFinished = (0, _react.useMemo)(function () {
		return boxResults ? boxResults.finished || false : false;
	}, [boxResults]);

	//reducers

	var _useReducer = (0, _react.useReducer)(function (state, action) {
		if (action) {
			return [].concat(_toConsumableArray(state), _toConsumableArray(action));
		}
		return state;
	}, []),
	    _useReducer2 = _slicedToArray(_useReducer, 2),
	    factCards = _useReducer2[0],
	    setFactCards = _useReducer2[1];

	//effect hooks


	(0, _react.useEffect)(function () {
		isMounted = true;
		items.forEach(function (r) {
			if (r.type === 'Lesson') getFactCardsFromWorkforms(r);
			if (r.type === 'Film') getFactCardsFromWorkforms(r);
			if (r.type === 'MasterClass') getFactCardsFromElement(r);
			if (r.type === 'ExternalLinkCollection') getExternalLinkFromElement(r);
		});

		if (isMounted) {
			setShowCards(true);
		}

		setTestPercentage((0, _lib.getTestPercentage)(elementResults, items));
		return function () {
			return isMounted = false;
		};
	}, []);

	//functions
	var handleBottom = function handleBottom() {
		setShow(true);
	};

	var handleClick = function handleClick() {
		setShow(true);
		(0, _scrollTo2.default)(0, 0, {
			duration: 300
		});
	};

	var handleDisableClick = function handleDisableClick() {
		setShowDialog(true);
	};

	var handleDisableTestClick = function handleDisableTestClick() {
		setShowTestDialog(true);
	};

	var handleClose = function handleClose() {
		setShowDialog(false);
		setShowTestDialog(false);
	};

	var externalLinkje = {};

	var getExternalLinkFromElement = function getExternalLinkFromElement(el) {
		var client = props.client;

		client.query({
			query: _graphql.GQL_FETCH_CMS_EXTERNAL_LINK_COLLECTION,
			variables: {
				id: el.id
			}
		}).then(function (result) {
			if (result.data && result.data.externalLinkCollection) {
				var _result$data$external = result.data.externalLinkCollection,
				    externalLinkCollection = _result$data$external === undefined ? {} : _result$data$external;


				if (externalLinkCollection) {
					externalLinkje[externalLinkCollection.id] = externalLinkCollection;

					setExternalLink(externalLinkje);
				}
			}
		});
	};

	var getFactCardsFromElement = function getFactCardsFromElement(el) {
		var client = props.client;

		client.query({
			query: _graphql.GQL_FETCH_MASTERCLASS,
			variables: {
				id: el.id
			}
		}).then(function (result) {
			if (result.data && result.data.masterClass) {
				var _result$data$masterCl = result.data.masterClass.factCards,
				    _factCards2 = _result$data$masterCl === undefined ? [] : _result$data$masterCl;

				if (_factCards2.length > 0) {
					var _factCards = _factCards2.map(function (item) {
						return item.id;
					});
					setImage(_factCards2[0].image || image);
					setFactCards(_factCards);
				}
			}
		});
	};

	var getFactCardsFromWorkforms = function getFactCardsFromWorkforms(el) {
		var client = props.client;

		if (el.workforms) {
			el.workforms.forEach(function (item) {
				if (item.type == 'FactCardReferral') {
					client.query({
						query: _graphql.GQL_FETCH_FACTCARDREFERRAL,
						variables: {
							id: item.id
						}
					}).then(function (result) {
						if (result.data && result.data.factCardReferral) {
							var _result$data$factCard = result.data.factCardReferral.referrals,
							    referrals = _result$data$factCard === undefined ? [] : _result$data$factCard;

							var _factCards3 = referrals.map(function (item) {
								return item.factCardId;
							});

							client.query({
								query: _graphql.GQL_FETCH_CMS_FACT_CARD,
								variables: {
									id: referrals[0] && referrals[0].factCardId
								}
							}).then(function (r) {
								if (r.data && r.data.factCard) {
									var _r$data$factCard = r.data.factCard,
									    factCard = _r$data$factCard === undefined ? {} : _r$data$factCard;

									setFactCards(_factCards3);
									setImage(factCard.image || image);
								}
							});
						}
					});
				}
			});
		}
	};

	var _items = [].concat(_toConsumableArray(items)) || [];
	var hasEntryType = false;
	var entryDone = true;

	_items.forEach(function (e, index) {
		elementResults && elementResults.forEach(function (a) {
			if (a.element.id == e.id) {
				e.done = a.finished;
				e.correct = a.correct;
				e.rating = a.rating;
			}
		});
		if (e.type === 'EntryTest') {
			hasEntryType = index;
		}
		e.new = false;
	});

	if (_items.find(function (e) {
		return !e.done;
	})) {
		_items.find(function (e) {
			return !e.done;
		})['new'] = true;
	}

	if (_items[hasEntryType]) {
		entryDone = _items[hasEntryType].done ? _items[hasEntryType].done : false;
	}

	return _react2.default.createElement(
		_react2.default.Fragment,
		null,
		_react2.default.createElement(_reactBottomScrollListener2.default, { onBottom: handleBottom, debounce: '0' }),
		_react2.default.createElement(
			'div',
			{
				onClick: handleClick,
				className: (0, _classnames2.default)(classes.up, _defineProperty({}, classes.show, show))
			},
			_react2.default.createElement(_KeyboardArrowUp2.default, null)
		),
		_react2.default.createElement(
			_Grid2.default,
			{ container: true, spacing: 4, className: classes.grid },
			_items.filter(function (c) {
				return c.type === 'EntryTest';
			}).map(function (item, idx) {
				return _react2.default.createElement(
					_Grid2.default,
					{ item: true, xs: 12, md: 12, key: item.id },
					_react2.default.createElement(_EntryTestCard2.default, { idx: 0, show: showCards, item: item })
				);
			}),
			_items.filter(function (c) {
				return c.type !== 'EntryTest' && c.type !== 'Summary' && c.type !== 'Test';
			}).map(function (item, idx) {
				return _react2.default.createElement(
					_react2.default.Fragment,
					{ key: item.id },
					item.type == 'SlideshowCreator' ? _react2.default.createElement(
						_appCore.AccessControl,
						{ role: [].concat(_toConsumableArray(_lib2.teacherRoles), [_lib2.schoolManagerRole]) },
						_react2.default.createElement(
							_Grid2.default,
							{ item: true, xs: 12, key: item.id, md: item.fullWidth ? 12 : 6 },
							_react2.default.createElement(_ElementGridCard2.default, { item: item, idx: idx })
						)
					) : item.type === 'FactCardCollection' && item.variant === _FactCardsCard.FactCardCollectionVariant.ALL ? _react2.default.createElement(
						_Grid2.default,
						{ item: true, xs: 12, md: 12 },
						_react2.default.createElement(_FactCardsCard2.default, {
							item: item,
							image: image,
							factCards: factCards,
							disabled: !entryDone,
							onDisabledClick: handleDisableClick
						})
					) : item.type === 'ExternalLinkCollection' ? _react2.default.createElement(
						_Grid2.default,
						{ item: true, xs: 12, md: 12 },
						_react2.default.createElement(_ExternalLinkCard2.default, {
							item: item,
							image: image,
							externalLink: externalLink[item.id],
							disabled: !entryDone,
							onDisabledClick: handleDisableClick
						})
					) : item.type === 'DigitalBook' ? _react2.default.createElement(
						_Grid2.default,
						{ item: true, xs: 12, md: 12 },
						_react2.default.createElement(_DigitalBookCard2.default, { item: item, image: image, disabled: !entryDone, onDisabledClick: handleDisableClick })
					) : _react2.default.createElement(
						_Grid2.default,
						{ item: true, xs: 12, md: item.fullWidth ? 12 : 6 },
						_react2.default.createElement(_ElementGridCard2.default, { item: item, idx: idx, disabled: !entryDone, onDisabledClick: handleDisableClick })
					)
				);
			}),
			factCards.length > 0 && !_items.some(function (item) {
				return item.type === 'FactCardCollection';
			}) && _react2.default.createElement(
				_Grid2.default,
				{ item: true, xs: 12, md: 12 },
				_react2.default.createElement(_FactCardsCard2.default, { image: image, factCards: factCards, disabled: !entryDone, onDisabledClick: handleDisableClick })
			),
			_items.filter(function (c) {
				return c.type === 'Summary';
			}).map(function (item) {
				return _react2.default.createElement(
					_Grid2.default,
					{ item: true, xs: 12, md: false ? 12 : 6, key: item.id },
					_react2.default.createElement(_TestCard2.default, {
						item: item,
						secondaryColors: true,
						show: showCards,
						hideIcon: true,
						url: '/recap/' + item.id,
						title: item.title || '',
						content: item.introText || '',
						buttonText: t('app.elements.element-card.view-summary'),
						disabled: !entryDone,
						onDisabledClick: handleDisableClick
					})
				);
			}),
			_items.filter(function (c) {
				return c.type === 'Test';
			}).map(function (item) {
				return _react2.default.createElement(
					_Grid2.default,
					{ item: true, xs: 12, md: false ? 12 : 6, key: item.id },
					_react2.default.createElement(_TestCard2.default, {
						item: item,
						show: showCards,
						secondaryColors: true,
						hideIcon: true,
						url: '/test/' + item.id,
						title: item.title || '',
						content: item.introText || '',
						buttonText: t('app.elements.element-card.test-card.action'),
						boxFinished: boxFinished,
						disabled: boxFinished ? false : testPercentage < 100 || !entryDone,
						onDisabledClick: handleDisableTestClick
					})
				);
			})
		),
		_react2.default.createElement(
			_Dialog2.default,
			{ open: showDialog, onClose: handleClose, PaperProps: { className: classes.dialogPaper } },
			_react2.default.createElement(
				_Typography2.default,
				{ variant: 'h6' },
				t('app.elements.element-card.entry-test-dialog.title')
			),
			_react2.default.createElement(
				_Typography2.default,
				{ variant: 'body1', className: classes.body },
				t('app.elements.element-card.entry-test-dialog.description')
			)
		),
		_react2.default.createElement(
			_Dialog2.default,
			{ open: showTestDialog, onClose: handleClose, PaperProps: { className: classes.dialogPaper } },
			_react2.default.createElement(
				_Typography2.default,
				{ variant: 'h6' },
				t('app.elements.element-card.magazine-dialog.title')
			),
			_react2.default.createElement(
				_Typography2.default,
				{ className: classes.body },
				t('app.elements.element-card.magazine-dialog.description')
			)
		)
	);
};

exports.default = (0, _reactApollo.withApollo)(Elements);