import gql from 'graphql-tag';
import { fileAsset, appAssetWithThumbnail } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_TEST = gql`
    query($id: ID!) {
        test(id: $id) {
            id
            introTitle
            type
            preTitle
            image {
                ${appAssetWithThumbnail}
            }
            workforms {
                id
                type
            }
        }
    }
`;

export const GQL_UPDATE_BOX_RESULTS = gql`
    mutation(
        $id: ID
        $user: ID!
        $program: ID!
        $box: ID!
        $finished: Boolean
        $rating: String
        $text: String
    ) {
        upsertBoxResult(
            input: {
                id: $id
                user: $user
                program: $program
                box: $box
                finished: $finished
                rating: $rating
                text: $text
            }
        ) {
            result {
                user {
                    id
                }
                id
                lastModifiedOn
                finishedOn
                finished
                rating
                certificatePdfUrl
                box {
                    id
                }
                text
            }
        }
    }
`;

export const GQL_FETCH_GLOBAL_SETTINGS = gql`
    query {
        settings {
            settings {
                name
                value
                file {
                    ${fileAsset}
                }
            }
        }
    }
`;

export const GQL_FETCH_BOX_RESULTS = gql`
    query($program: ID!, $user: ID!) {
        boxResults(program: $program, user: $user) {
            user {
                id
            }
            id
            lastModifiedOn
            finishedOn
            finished
            rating
            certificatePdfUrl
            box {
                id
                mandatory
            }
            text
        }
    }
`;

export const GQL_FETCH_BOX = gql`
    query($id: ID!) {
        box(id: $id) {
            id
            yearProgress
            mandatory
        }
    }
`;

export const GQL_FETCH_APP_USER = gql`
    query($id: ID!) {
        appUser(id: $id) {
            id
            dateOfBirth
            userDataCbd {
                trainingMandatory2016
                trainingMandatory2017
                trainingMandatory2018
                trainingMandatory2019
                trainingMandatory2020
                trainingMandatory2021
                trainingMandatory2022
                trainingMandatory2023
                trainingMandatory2024
                trainingMandatory2025
                trainingMandatory2026
                trainingMandatory2027
                trainingMandatory2028
                trainingMandatory2029
                trainingMandatory2030
                function2016
                function2017
                function2018
                function2019
                function2020
                function2021
                function2022
                function2023
                function2024
                function2025
                function2026
                function2027
                function2028
                function2029
                function2030
                function
            }
        }
    }
`;
export const GQL_FETCH_ELEMENT_AVERAGE_RATING = gql`
    query elementAverageRating($element: ID!) {
        elementAverageRating(element: $element)
    }  
`;

export const GQL_FETCH_BOXES_IN_PROGRAM = gql`
    query boxesInProgram($id: ID!) {
        program(id: $id) {
            boxes {
                id
                yearProgress
            }
        }
    }
`;

export const GQL_FETCH_BOX_RESULT = gql`
    query boxResult($userId: ID!, $programId: ID!, $boxId: ID!) {
        boxResults(user: $userId, program: $programId, box: $boxId) {
            id
            finished
            box {
                yearProgress
            }
        }
    }
`;
