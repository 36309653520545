'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _selectors = require('../ProgramsDropdown/selectors');

var _selectors2 = require('@manakin/authentication/selectors');

var _selectors3 = require('../GroupsDropdown/selectors');

var _graphql = require('./graphql');

var _reactApollo = require('react-apollo');

var _cloneDeep = require('lodash/cloneDeep');

var _cloneDeep2 = _interopRequireDefault(_cloneDeep);

var _reactHooks = require('@apollo/react-hooks');

var _reactRedux = require('react-redux');

var _lib = require('../lib');

var _constants = require('@manakin/core/lib/constants');

var _lib2 = require('@manakin/app-core/lib');

var _actions = require('@manakin/core/actions');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');

var getProductFromClass = function getProductFromClass(schoolClass, program) {
	return schoolClass.products.find(function (product) {
		return product.program && product.program.id === program;
	});
};

var BoxService = function BoxService(props) {
	//props
	var children = props.children,
	    client = props.client,
	    programBoxesFilter = props.programBoxesFilter;

	//redux props

	var program = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors.getProgram)(state);
	});
	var schoolClass = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors.getSchoolclass)(state);
	});
	var group = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors3.getGroup)(state);
	});
	var config = (0, _reactRedux.useSelector)(function (state) {
		return state.config;
	});
	var appUser = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors2.getAppUser)(state);
	}) || {};

	//state hooks

	var _useState = (0, _react.useState)(5.5),
	    _useState2 = _slicedToArray(_useState, 2),
	    minGrade = _useState2[0],
	    setMinGrade = _useState2[1];

	var _useState3 = (0, _react.useState)(null),
	    _useState4 = _slicedToArray(_useState3, 2),
	    schoolClassId = _useState4[0],
	    setSchoolClassId = _useState4[1];

	//reduces


	var _useReducer = (0, _react.useReducer)(function (state, action) {
		if (action) {
			var obj = _extends({}, state, _defineProperty({}, action.boxId, Math.floor(action.percentage)));

			if (action.isLast) action.handleResults(action._data, obj);

			return obj;
		}

		return state;
	}, {}),
	    _useReducer2 = _slicedToArray(_useReducer, 2),
	    percentagePerBox = _useReducer2[0],
	    setPercentagePerBox = _useReducer2[1];

	var _useReducer3 = (0, _react.useReducer)(function (state, action) {
		if (action && action.length) return [].concat(_toConsumableArray(state), _toConsumableArray(action));

		return [];
	}, []),
	    _useReducer4 = _slicedToArray(_useReducer3, 2),
	    boxes = _useReducer4[0],
	    setBoxes = _useReducer4[1];

	var _useReducer5 = (0, _react.useReducer)(function (state, action) {
		if (action && action.length) return [].concat(_toConsumableArray(state), _toConsumableArray(action));
		return [];
	}, []),
	    _useReducer6 = _slicedToArray(_useReducer5, 2),
	    milestones = _useReducer6[0],
	    setMilestones = _useReducer6[1];

	var filterIsActive = Object.keys(programBoxesFilter || {}).length > 0;

	//queries

	var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_CURRENT_USER),
	    data = _useQuery.data,
	    loading = _useQuery.loading;

	var _useQuery2 = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_BOX_RESULTS, {
		variables: {
			program: program,
			user: appUser.id
		}
	}),
	    _useQuery2$data = _useQuery2.data,
	    boxResultsData = _useQuery2$data === undefined ? {} : _useQuery2$data,
	    boxResultsLoadig = _useQuery2.loading;

	var _useQuery3 = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_GLOBAL_SETTINGS),
	    _useQuery3$data = _useQuery3.data,
	    globalSettingsData = _useQuery3$data === undefined ? {} : _useQuery3$data,
	    globalSettingsDataLoading = _useQuery3.loading;

	var _globalSettingsData$s = globalSettingsData.settings,
	    settings = _globalSettingsData$s === undefined ? {} : _globalSettingsData$s;

	var _useLazyQuery = (0, _reactHooks.useLazyQuery)(_graphql.GQL_FETCH_PROGRAM, {
		fetchPolicy: 'no-cache'
	}),
	    _useLazyQuery2 = _slicedToArray(_useLazyQuery, 2),
	    fetchProgram = _useLazyQuery2[0],
	    _useLazyQuery2$ = _useLazyQuery2[1],
	    programData = _useLazyQuery2$.data,
	    programDataLoading = _useLazyQuery2$.loading;

	var _useLazyQuery3 = (0, _reactHooks.useLazyQuery)(_graphql.GQL_FETCH_SCHOOL_CLASS_PROGRAM, {
		fetchPolicy: 'no-cache'
	}),
	    _useLazyQuery4 = _slicedToArray(_useLazyQuery3, 2),
	    fetchSchoolClassProgram = _useLazyQuery4[0],
	    _useLazyQuery4$ = _useLazyQuery4[1],
	    schoolClassProgramData = _useLazyQuery4$.data,
	    schoolClassProgramDataLoading = _useLazyQuery4$.loading;

	(0, _react.useEffect)(function () {
		// Only refetch program if the filter contains any filtering
		if (filterIsActive) {
			handleProgram();
		}
	}, [programBoxesFilter]);

	//effect hooks
	(0, _react.useEffect)(function () {
		if (programData && programData.program && !programDataLoading) {
			getBoxResults(programData, programData.program.id);
			if (filterIsActive) {
				setBoxes();
			}
		}
	}, [programDataLoading, programData]);

	(0, _react.useEffect)(function () {
		if (schoolClassProgramData && schoolClassProgramData.schoolClassProgram && !schoolClassProgramDataLoading) {
			setBoxes();
			setBoxes(schoolClassProgramData.schoolClassProgram.boxes);
			setMilestones(schoolClassProgramData.schoolClassProgram.milestones);
		}
	}, [schoolClassProgramDataLoading, schoolClassProgramData]);

	(0, _react.useEffect)(function () {
		if (programData && programData.program) {
			// Re-calculate the box results with the new milestones or boxes
			// handleAppUserAfterBoxes();
			getBoxResults(programData, programData.program.id);
		}
	}, [boxes, milestones]);

	(0, _react.useEffect)(function () {
		if (!loading && !boxResultsLoadig && !globalSettingsDataLoading && (!schoolClass || !schoolClass.id)) {
			handleAppUser();
		}
		if (!globalSettingsDataLoading) {
			if (settings.settings) {
				settings.settings.forEach(function (item) {
					if (item.name === _constants.SettingsKeys.MIN_GRADE) {
						setMinGrade(item.value);
					}
				});
			}
		}
	}, [loading, boxResultsLoadig, globalSettingsDataLoading, program]);

	(0, _react.useEffect)(function () {
		if (schoolClass && schoolClass.products && schoolClass.products.length) {
			var product = getProductFromClass(schoolClass, program);

			if (product && product.program) {
				setSchoolClassId(schoolClass.id);
				handleProgram(schoolClassId);
			}
		}
	}, [schoolClass]);

	(0, _react.useEffect)(function () {
		if (group) {
			if (group.type === 'SchoolClass') {
				if (!(0, _lib2.userHasAnyRole)(appUser, _lib2.studentRoles)) {
					setSchoolClassId(group.id);
				}
			} else {
				setSchoolClassId(undefined);
			}
		}
	}, [group]);

	//functions
	var handleAppUser = function handleAppUser() {
		setBoxes();

		var _ref = data || {},
		    _ref$currentAppUser = _ref.currentAppUser,
		    currentAppUser = _ref$currentAppUser === undefined ? {} : _ref$currentAppUser;

		var _currentAppUser$schoo = currentAppUser.schoolClasses,
		    schoolClasses = _currentAppUser$schoo === undefined ? [] : _currentAppUser$schoo;


		if (schoolClasses && schoolClasses.length && (0, _lib2.userHasAnyRole)(currentAppUser, _lib2.studentRoles)) {
			setSchoolClassId(schoolClasses[0].id);
			handleProgram(schoolClasses[0].id);
		} else {
			handleProgram();
		}
	};

	var handleAppUserAfterBoxes = function handleAppUserAfterBoxes() {
		var _ref2 = data || {},
		    _ref2$currentAppUser = _ref2.currentAppUser,
		    currentAppUser = _ref2$currentAppUser === undefined ? {} : _ref2$currentAppUser;

		var _currentAppUser$schoo2 = currentAppUser.schoolClasses,
		    schoolClasses = _currentAppUser$schoo2 === undefined ? [] : _currentAppUser$schoo2;


		if (schoolClasses && schoolClasses.length && (0, _lib2.userHasAnyRole)(currentAppUser, _lib2.studentRoles)) {
			handleProgramAfterBoxes(schoolClasses[0].id);
		} else {
			handleProgramAfterBoxes();
		}
	};

	var handleProgramAfterBoxes = function handleProgramAfterBoxes(schoolClassId) {
		if (schoolClass && schoolClass.products && schoolClass.products.length) {
			var product = getProductFromClass(schoolClass, program);
			fetchBoxes(product.program.id, schoolClass.id);
		} else if (program && typeof program == 'string') {
			fetchBoxes(program, schoolClassId);
		}
	};

	var handleProgram = function handleProgram(schoolClassId) {
		if (schoolClass && schoolClass.products && schoolClass.products.length) {
			var product = getProductFromClass(schoolClass, program);
			if (programData && programData.program && programData.program.boxes) {
				setBoxes();
				setBoxes(programData.program.boxes);
			}
			fetchBoxes(product.program.id, schoolClass.id);
		} else if (program && typeof program == 'string') {
			fetchBoxes(program, schoolClassId);
		} else {
			setTimeout(function () {
				if (!group) {
					if (props.onLoadChange) props.onLoadChange(false);
				}
			}, 3000);
		}
	};

	var fetchBoxes = function fetchBoxes(programId, schoolClass) {
		if (props.onLoadChange) props.onLoadChange(true);

		if ((schoolClass || schoolClassId) && !filterIsActive && (0, _actions.isSettingEnabled)(_constants.SettingsKeys.CHANGE_LESSON_ENABLED)) {
			// Only fetch if there is a school class and no sorting filter has been set
			fetchSchoolClassProgram({
				variables: {
					programId: programId,
					schoolClassId: schoolClass || schoolClassId
				}
			});
		}

		fetchProgram({
			variables: _extends({
				id: programId
			}, programBoxesFilter)
		});
	};

	var getElementResults = function getElementResults(box, programId) {
		return client.query({
			query: _graphql.GQL_FETCH_ELEMENT_RESULTS,
			variables: {
				box: box.id,
				program: programId,
				user: appUser.id
			}
		});
	};

	var getBoxResults = function getBoxResults(_data, programId) {
		if (props.onLoadChange) props.onLoadChange(true);

		var _program = (0, _cloneDeep2.default)(_data);
		var loopIdx = 0;
		var loopIndex = 0;
		var boxLength = 0;

		if (boxes.length) {
			_program.program.boxes = boxes;
		}
		if (_program.program && _program.program.boxes && appUser && appUser.id) {
			_program.program.boxes.forEach(function (box, index) {
				boxLength += box.length;
				box.forEach(function (_box, idx) {
					loopIndex++;
					getElementResults(_box, programId).then(function (r) {
						loopIdx++;

						var isLast = loopIndex >= _program.program.boxes.length && loopIdx >= boxLength;

						setPercentage(_box, r, _program, isLast);
					}, function (e) {
						if (props.onLoadChange) {
							props.onLoadChange(false);
						}
					});
				});
			});
		} else {
			if (props.onLoadChange) props.onLoadChange(false);
		}
	};

	var setPercentage = function setPercentage(box, results, _data, isLast) {
		var _program = (0, _cloneDeep2.default)(_data);
		var _boxResultsData$boxRe = boxResultsData.boxResults,
		    boxResults = _boxResultsData$boxRe === undefined ? [] : _boxResultsData$boxRe;

		var percentage = (0, _lib.newGetPercentage)(boxResults, box.id);

		var _results$data = results.data,
		    resultsData = _results$data === undefined ? [] : _results$data;

		if (resultsData) {
			var _boxResultsData$boxRe2 = boxResultsData.boxResults,
			    _boxResults = _boxResultsData$boxRe2 === undefined ? [] : _boxResultsData$boxRe2;

			if (config.general && config.general.useBoxResults) {
				if (_boxResults.some(function (item) {
					return item.box.id === box.id && item.finished;
				})) {
					percentage = 100;
				} else if (_boxResults.some(function (item) {
					return item.box.id === box.id && !item.finished;
				}) && percentage === 100) {
					percentage = 98;
				}
			}

			setPercentagePerBox({
				boxId: box.id,
				percentage: Math.floor(percentage),
				isLast: isLast,
				_data: _program,
				handleResults: handleResults
			});
		}
	};

	var handleResults = function handleResults(_data, ppb) {
		if (props.onLoadChange) props.onLoadChange(false);
		var resumeBox = null;
		var _program = (0, _cloneDeep2.default)(_data);

		if (_program.program) {
			if (boxes.length) {
				_program.program.boxes = boxes;
			}
			if (milestones.length) {
				_program.program.milestones = milestones;
			}
			if (appUser && appUser.boxId && appUser.programId) {
				if (appUser.programId === _program.program.id) {
					_program.program.boxes && _program.program.boxes.forEach(function (_box) {
						return _box.forEach(function (box) {
							if (box.id === appUser.boxId) resumeBox = _extends({}, box, {
								time: appUser.elementId
							});
						});
					});
				}
			}
		}

		props.onNewData(_program, ppb, resumeBox);
	};

	return _react2.default.createElement(
		_react2.default.Fragment,
		null,
		children
	);
};

exports.default = (0, _reactApollo.withApollo)(BoxService);